import {PracticeApptReason} from "@services/monolith/appointmentReasons";
import {ApptSlot} from "@services/monolith/availability";
import {compact} from "lodash";
import {useCallback, useMemo} from "react";

import {fetchReasonBySlug} from "../_services/api";
import {
  maybePairLocsWithSlots,
  reduceLocSlotPairsToSoonestSlot,
} from "../components/v5/Locations/usePairLocsWithSlots";
import {QueryState, useQueryController} from "../hooks/useQueryController";
import {getSoonestVirtualSlot} from "../hooks/useSoonestVirtualSlot";
import {useTypedSelector} from "../store";
import {doesLocationHaveASpecialtyId} from "../utils/filterLocationsBySpecialtyId";
import {regionFilter} from "../utils/locationsByRegion";
import {getStateCodeFromRegionSlug} from "../utils/stateUtils";

export const isReasonOnlyVirtual = (practiceApptReason: PracticeApptReason): boolean =>
  practiceApptReason &&
  practiceApptReason.supportsVideoVisit.compact().length ===
    practiceApptReason.supportsVideoVisit.length;

const fetchSoonestSlotByReasonSlug = (
  apptReason: PracticeApptReason,
  locations: {id: string; specialtyIds: string[]}[],
  selectedRegion?: string,
) =>
  selectedRegion && isReasonOnlyVirtual(apptReason)
    ? getSoonestVirtualSlot(
        apptReason.specialtyIds,
        // @ts-expect-error TS2345: Argument of type 'string | null' is not assignable to parameter of type 'string'.
        getStateCodeFromRegionSlug(selectedRegion),
        apptReason.id,
      )
    : maybePairLocsWithSlots(locations, apptReason).then(reduceLocSlotPairsToSoonestSlot);

const reduceApptSlotsToSoonest = (apptSlots: ApptSlot[]): ApptSlot | null =>
  apptSlots.reduce<ApptSlot | null>((acc, next) => {
    const isNextValid = Number.isInteger(next?.time);
    const isFirstValid = acc === null;
    const nextIsSooner = next?.time < (acc?.time || Infinity);
    return isNextValid && (isFirstValid || nextIsSooner) ? next : acc;
  }, null);

const fetchSoonestSlotsForSlugs = (
  slugs: string[],
  locations: {id: string; region: {slug: string}; specialtyIds: string[]}[],
  selectedRegion?: string,
) =>
  Promise.all(
    slugs.map(async slug => {
      const apptReason = await fetchReasonBySlug(slug);
      const locationsToCheck = locations
        .filter(selectedRegion ? regionFilter(selectedRegion) : Boolean)
        .filter(doesLocationHaveASpecialtyId(apptReason.specialtyIds))
        .slice(0, 3);

      const soonestSlot = await fetchSoonestSlotByReasonSlug(
        apptReason,
        locationsToCheck,
        selectedRegion,
      );

      return {apptReason, soonestSlot};
    }),
  );

export const useSoonestSlotByReasonSlugsQuery = (
  slugs: string[],
  selectedLocation?: {id: string; slug: string; region: {slug: string}; specialtyIds: string[]},
  selectedRegion?: string,
  skip = false,
): QueryState<ApptSlot | null> => {
  const storeLocations = useTypedSelector(state => state.config.locations);
  const locationsToCheck = useMemo(
    () => (selectedLocation ? [selectedLocation] : storeLocations),
    [selectedLocation, storeLocations],
  );

  const fn = useCallback(
    () =>
      fetchSoonestSlotsForSlugs(slugs, locationsToCheck, selectedRegion)
        .then(results => compact(results.map(result => result.soonestSlot)))
        .then(reduceApptSlotsToSoonest),
    [locationsToCheck, selectedRegion, slugs],
  );

  return useQueryController({
    fn,
    cacheKey: JSON.stringify({
      slugs,
      selectedLocation: selectedLocation?.slug,
      selectedRegion,
    }),
    skip,
    ifRejectedValue: null,
    initialValue: null,
  });
};
