import {sendInitiateWebSchedulingEvent} from "@components/Locations/events";
import {useRouter} from "next/router";
import React, {useCallback} from "react";

import {QueryStatus} from "../../../hooks/useQueryController";
import {useReadableSlotTime} from "../../../hooks/useReadableSlotTime";
import {getSchedulingLink} from "../../../page-containers/booking/utils/bookingQuery";
import {useTypedSelector} from "../../../store";
import {selectSelectedRegion} from "../../../store/slices/userLocation";
import {v5Pages} from "../../_common/_constants";
import {CareDiscoveryRowClickReporter} from "../Reason/CareDiscoverySearch/careDiscoveryAnalytics";
import {getAllRelatedCares} from "../Reason/CareDiscoverySearch/getAllRelatedCares";
import {useTopicStack} from "../Reason/CareDiscoverySearch/useTopicStack";
import CareEntryRow from "./Presentation";
import {useSoonestSlotForResult} from "./useSoonestSlotForResult";
import {CareEntryRowVariant, ExtendedCareEntrySearchResult} from ".";

export type SelectedLocation = {
  address: {
    state: string;
  };
  id: string;
  practiceId: string;
  region: {slug: string};
  slug: string;
  specialtyIds: string[];
};

type Props = {
  result: ExtendedCareEntrySearchResult;
  rank: number;
  query?: string;
  variant?: CareEntryRowVariant;
  selectedLocation?: SelectedLocation;
  shouldExpandIfTopic?: boolean;
  isLoading?: boolean;
  reportClickEvent?: CareDiscoveryRowClickReporter;
  cypressKey: string;
  maybeToggleDialog?: (shouldRedirect: boolean) => void;
  maybeAddCareToTopicStack?: ReturnType<typeof useTopicStack>["maybeAddToStack"];
  /**
   * Prevents row clicks from firing `AppointmentBookingStarted` events after flow has already started.
   */
  canSendApptStartEvent?: boolean;
  viewId?: string;
};

const CareEntryRowControl = ({
  result,
  selectedLocation,
  query,
  rank,
  variant = CareEntryRowVariant.MD,
  isLoading = false,
  reportClickEvent,
  maybeToggleDialog,
  maybeAddCareToTopicStack,
  cypressKey,
  canSendApptStartEvent = false,
  viewId,
}: Props) => {
  const router = useRouter();
  const isTopic = !result.appointment_reason;
  const {
    name,
    brief_description,
    slug,
    turnaround,
    price,
    override_url,
    icon = "defaultReasonIcon",
  } = result;

  const selectedRegion = useTypedSelector(selectSelectedRegion);
  const soonestSlotQuery = useSoonestSlotForResult(result, selectedLocation, selectedRegion);
  const soonestSlotTime = soonestSlotQuery.data?.time;

  const readableSlotTime = useReadableSlotTime(soonestSlotTime, {
    withNext: true,
  });

  const maybeAddSelfToStack = maybeAddCareToTopicStack?.(
    result.slug,
    Boolean(getAllRelatedCares(result).length),
  );

  const onClick = useCallback(() => {
    const shouldStartBooking = !isTopic && selectedLocation && soonestSlotTime;
    if (maybeAddSelfToStack) {
      maybeAddSelfToStack(result);
      reportClickEvent?.(result, soonestSlotQuery.data, rank, undefined, viewId);
    } else if (shouldStartBooking) {
      const specialtyId = soonestSlotQuery.data?.specialtyIds?.[0];
      const {bookingLink, flowId, WEB_SCHEDULING_ENABLED} = getSchedulingLink({
        specialtyIds: soonestSlotQuery.data?.specialtyIds || [],
        webSchedulingQuery: {
          locationSlug: selectedLocation?.slug,
          appointmentReasonSlug: result.slug,
          originUrl: router.asPath,
          initialCalendarTime: soonestSlotTime,
          specialtyId,
        },
        patientAppSchedulingQuery: {
          practiceId: selectedLocation?.practiceId,
          specialtyId,
          apptReasonId: result.appointment_reason || undefined,
          locationId: selectedLocation?.id,
          preselectedState: selectedLocation?.address.state,
        },
      });

      if (WEB_SCHEDULING_ENABLED && canSendApptStartEvent) {
        sendInitiateWebSchedulingEvent(flowId);
      }

      reportClickEvent?.(result, soonestSlotQuery.data, rank, flowId, viewId);

      router.push(bookingLink);
    } else {
      reportClickEvent?.(result, soonestSlotQuery.data, rank, undefined, viewId);
      const carePageHref = {pathname: v5Pages.getCareSlug, query: {slug}};
      const href = override_url || result.href || carePageHref;
      if (href) {
        router.push(href);
      }
    }

    maybeToggleDialog?.(!maybeAddSelfToStack);
  }, [
    maybeAddSelfToStack,
    maybeToggleDialog,
    result,
    reportClickEvent,
    soonestSlotQuery.data,
    rank,
    viewId,
    selectedLocation,
    soonestSlotTime,
    isTopic,
    slug,
    override_url,
    canSendApptStartEvent,
    router,
  ]);

  return (
    <CareEntryRow
      cypressKey={cypressKey}
      query={query}
      onClick={onClick}
      icon={icon}
      line1={name}
      line2={brief_description || undefined}
      price={price}
      turnaround={turnaround}
      isSlotLoading={soonestSlotQuery.status === QueryStatus.LOADING}
      readableSlotTime={readableSlotTime || undefined}
      variant={variant}
      isLoading={isLoading}
    />
  );
};
CareEntryRowControl.displayName = "CareEntryRowControl";
export default React.memo(CareEntryRowControl);
